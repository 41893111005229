<mat-toolbar class="topbar mat-bg-card">
  <!-- Sidenav toggle button -->
  <button
    *ngIf="layoutConf?.sidebarStyle !== 'compact'"
    mat-icon-button
    id="sidenavToggle"
    (click)="toggleSidenav()"
    matTooltip="Toggle Hide/Open"
  >
    <mat-icon>menu</mat-icon>
  </button>

  <span fxFlex></span>
  <!-- Language Switcher -->
  <!--button mat-button [matMenuTriggerFor]="menu">
    <span class="fi fi-{{currentLang.flag}} mr-8"></span>
    <span>{{currentLang.name}}</span>
  </button-->
<mat-menu #menu="matMenu">
  <button mat-menu-item *ngFor="let lang of availableLangs" (click)="setLang(lang)">
        <span class="fi mr-8 fi-{{lang.flag}}"></span>
        <span>{{lang.name}}</span>
  </button>
</mat-menu>


  <!--egret-search-input-over placeholder="Country (e.g. US)" resultPage="/search"></egret-search-input-over-->
  <!-- Open "views/search-view/result-page.component" to know more about subscribing to input field value -->

  <!-- Notification toggle button -->
    <!--button
      mat-icon-button
      matTooltip="Notificaciones"
      (click)="toggleNotific()"
      [style.overflow]="'visible'"
      class="topbar-button-right"
    >
      <mat-icon>notifications</mat-icon>
      <span class="notification-number mat-bg-warn">3</span>
    </button-->
  <!-- Top left user menu -->
    <button
      mat-icon-button
      matTooltip="Monitoreo"
      [routerLink]="'/admin/monitor'"
      [style.overflow]="'visible'"
      class="topbar-button-right"
    >
      <mat-icon>view_module</mat-icon>
    </button>
  <button
    mat-icon-button
    [matMenuTriggerFor]="accountMenu"
    class="topbar-button-right img-button"
  >
    <img src="assets/images/face-default.jpeg" alt="" />
  </button>

  <mat-menu #accountMenu="matMenu">
    <button mat-menu-item (click)="jwtAuth.signout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>{{ "SIGNOUT" | translate }}</span>
    </button>
  </mat-menu>
</mat-toolbar>
